import styled from 'styled-components';
import { Paper } from '@material-ui/core';

const FormPaper = styled(Paper)`
  && {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 24px;
  }
`;

export default FormPaper;
