import { Avatar } from '@material-ui/core';
import styled from 'styled-components';

const FormAvatar = styled(Avatar)`
  && {
    margin: 8px;
  }
`;

export default FormAvatar;
