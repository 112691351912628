import React, { SyntheticEvent } from 'react';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {
  Container,
  Typography,
  InputLabel,
  Input,
  FormControl,
  Button,
  Box,
} from '@material-ui/core';
import SEO from '../components/seo';
import FormPaper from '../components/account/form/form-paper';
import CircularProgressOverlay from '../components/account/form/circular-progress-overlay';
import FormAvatar from '../components/account/form/form-avatar';
import ButtonProgressContainer from '../components/account/form/button-progress-container';
import useAuth from '../State/Auth/useAuth';

const ForgotPassword = (): JSX.Element => {
  const auth = useAuth();
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [_sent, setSent] = React.useState(false);

  const onClick = async (): Promise<void> => {
    setLoading(true);
    try {
      await auth.sendPasswordResetEmail(email);
      setLoading(false);
      setSent(true);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO title="Login" />
      <Container maxWidth="sm">
        <FormPaper>
          <FormAvatar>
            <LockOutlinedIcon />
          </FormAvatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input
              id="email"
              name="email"
              value={email}
              onChange={(e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>): void =>
                setEmail(e.currentTarget.value)
              }
              autoComplete="email"
            />
          </FormControl>
          <Box mt={2} mb={2} width="100%">
            <ButtonProgressContainer fullWidth>
              <Button
                disabled={loading || !email}
                type="submit"
                onClick={onClick}
                fullWidth
                variant="contained"
                color="primary"
                component="button"
              >
                Send reset link
              </Button>
              {loading && <CircularProgressOverlay size={30} />}
            </ButtonProgressContainer>
          </Box>
        </FormPaper>
      </Container>
    </>
  );
};

export default ForgotPassword;
